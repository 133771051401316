import React ,{useEffect} from "react";
import { useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";
import CourseBanner from '../../components/CourseBanner'
import HomeProduct from "../../components/HomeProduct";

const Men = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  const bannerData = {
       
    image: "/images/service/mens.jpg",
    heading: "Men's Glasses",
    para: "Discover Spectica's range of men's glasses, where style meets functionality. Choose from sleek, modern designs to timeless classics, all crafted for comfort and clarity. Elevate your look with the perfect pair tailored for the modern man.",
  };
  return (
    <div className="page-content">
    <Helmet>
        <title>Men Glasses</title>
        <meta name="description" content="
        and innovation." />
       
    </Helmet>
    <CourseBanner {...bannerData}/>

    <section>
    <HomeProduct/>
    
    </section>



     
 



   

      


      
    </div>
  );
};

export default Men;
