import React ,{useEffect} from "react";
import { useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";
import CourseBanner from '../../components/CourseBanner'
import HomeProduct from "../../components/HomeProduct";

const Eyeglasses = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  const bannerData = {
       
    image: "/images/service/shop-banner.jpg",
    heading: "Eyeglasses",
    para: "Explore Spectica's diverse range of stylish, high-quality eyeglasses tailored for every taste and need. Our collection combines fashion with function, ensuring you don't just see better, but also look great. Find your perfect pair and express your personal style with Spectica.",
  };
  return (
    <div className="page-content">
    <Helmet>
        <title>Eyeglasses</title>
        <meta name="description" content="lorem" />
   
    </Helmet>
    <CourseBanner {...bannerData}/>

    <section>
    <HomeProduct/>
    
    </section>



     
 



   

      


      
    </div>
  );
};

export default Eyeglasses;
