import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import CourseBanner from "../components/CourseBanner";
import OurServices from "../components/OurServices";

const About = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const bannerData = {
    image: "/images/about-banner.jpg",
    heading: "About Us",
    para: "Welcome to Spectica, where vision meets style. As connoisseurs of eye care and fashion, we're dedicated to providing you with the highest quality eyewear that not only corrects and protects your vision but also enhances your individual style.",
  };
  return (
    <div className="page-content">
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="
        and innovation."
        />
        <link rel="canonical" href="https://steamvarsity.com/about-us" />
      </Helmet>
      <CourseBanner {...bannerData} />
      <section className="bg2">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6">
              <div className="wrapper">
                <h2>Who We Are</h2>
                <p>
                  Spectica is more than just an eyewear brand; we are a
                  community of visionaries dedicated to enhancing how you see
                  the world. Founded on the principles of quality, innovation,
                  and style, our mission is to provide eyewear that not only
                  improves vision but also complements the individuality of each
                  wearer. With a deep understanding of the importance of both
                  function and fashion, Spectica has become a trusted name in
                  the eyewear industry.
                </p>
                <p>
                  Our team comprises skilled professionals passionate about
                  crafting the perfect pair of glasses for every face shape,
                  style preference, and vision need. From the meticulous design
                  process to the careful selection of materials, every pair of
                  Spectica glasses is a testament to our commitment to
                  excellence. We believe that the right pair of glasses can
                  transform a look, enhance confidence, and provide a new
                  perspective.
                </p>{" "}
                <p>
                  At Spectica, we are constantly pushing the boundaries of
                  design and technology. We embrace the latest trends while
                  staying true to timeless style and comfort. Our collection
                  ranges from classic silhouettes to modern innovations,
                  ensuring there's a perfect match for everyone. We are
                  dedicated to making high-quality eyewear accessible, offering
                  a diverse range of products that cater to all budgets without
                  compromising on quality or style.
                </p>{" "}
                <p>
                  We understand that choosing the right eyewear is a personal
                  journey. That's why our customer service team is dedicated to
                  providing a seamless and supportive experience. Whether you're
                  making your first purchase or returning for a new pair, we're
                  here to assist every step of the way. Join us at Spectica,
                  where we're not just selling glasses—we're creating clearer
                  visions and bolder futures.
                </p>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="img-wrapper">
                <img src="/images/about-us.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <OurServices />
      <section className="bg2">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6">
              <div className="wrapper">
                <h2>Our Commitment to Quality</h2>

                <p>
                At Spectica, our commitment to quality is the cornerstone of everything we do. We understand that eyewear is not just a fashion accessory but an essential tool for clear vision and daily comfort. That's why we invest in only the highest quality materials and the latest manufacturing techniques to ensure that every pair of glasses we offer is durable, comfortable, and precise. Our rigorous quality control process guarantees that each product meets our stringent standards before it reaches you, ensuring satisfaction and superior visual clarity.

               
                </p>
               
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="img-wrapper">
                <img src="/images/commitment.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-5">
              <div className="img-wrapper">
                <img src="/images/mission.jpg" alt="" />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-6">
              <div className="wrapper">
                <h2>Our Mission</h2>

                <p>
                Spectica's mission is to revolutionize the eyewear industry by delivering an unparalleled blend of style, comfort, and quality. We aim to empower individuals through a diverse range of superior eyewear that enhances vision and expresses personality. Our dedication goes beyond providing exceptional products; we strive to make a positive impact on our customers' lives by improving their vision and boosting their confidence.
                </p>
               
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
