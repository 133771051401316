import React from "react";
import { NavLink } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";

const OurServices = () => {
  return (
    <section>
      <div className="container py-5 service-wrapper">
        <div className="heading text-center pb-4">
          <h2>Our Services</h2>
          <p>We are Providing all services related to Eye Care</p>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="wrapper">
              <div className="img-wrapper">
                <img src="/images/home/service1.jpg" alt="" />
              </div>
              <div className="text-wrapper">
                <div className="text">
                  <h3>Zero Error 5 Points Computerised Eye Checkup</h3>
                  <p>
                  Our state-of-the-art computerized eye checkup ensures a zero-error diagnosis...
                  </p>
                  <NavLink className="effect" to="/service#service1">
                    Read More
                    <FaAngleRight />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="wrapper">
              <div className="img-wrapper">
                <img src="/images/home/service2.jpg" alt="" />
              </div>
              <div className="text-wrapper">
                <div className="text">
                  <h3>Wide Range of Frames & Sunglasses</h3>
                  <p>
                  We believe in providing our customers with an extensive selection of eyewear options. Our collection includes a...
                  </p>
                  <NavLink className="effect" to="/">
                    View Detail
                    <FaAngleRight />{" "}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="wrapper">
              <div className="img-wrapper">
                <img src="/images/home/service3.jpg" alt="" />
              </div>
              <div className="text-wrapper">
                <div className="text">
                  <h3>Comprehensive Contact Lens Selection</h3>
                  <p>
                  At Spectica, we understand that contact lenses are a preferred choice for many, offering...
                  </p>
                  <NavLink className="effect" to="/">
                    View Detail
                    <FaAngleRight />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurServices;
