import React from 'react'
import { FaRupeeSign } from "react-icons/fa";
import ProductData from './HomeProductData';

const HomeProduct = () => {
  return (
    <section className="py-5 bg2">
      <div className="container Products-wrapper">
      <div className="heading text-center pb-4">
      <h2>Our Products</h2>
      <p> Discover Spectica's Exclusive Collection: Where Quality Meets Style.
      Experience the Perfect Blend of Durability and Design with Our Eyewear.</p>
      </div>
        <div className="row">
        {ProductData.data.map((item, index) => (
          <div className="col-md-3 col-sm-6 mt-4" key={index}>
            <div className="wrapper">
            <div className="img-wrapper">
            <img src={item.img1} alt="category" />
            </div>
            <div className="text-wrapper">
            <div className="text text-center p-3">
            <h5 className="">
            {item.name}</h5>
          
            
            </div>
            </div>
            </div>
          </div>
          ))}
        </div>
      </div>



    </section>
  )
}

export default HomeProduct