import React ,{useEffect} from "react";
import { useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";
import CourseBanner from '../../components/CourseBanner'
import HomeProduct from "../../components/HomeProduct";

const Sunglassses = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  const bannerData = {
       
    image: "/images/service/sunglasses.jpg",
    heading: "Sunglassses",
    para: "Shield your eyes in style with Spectica's range of sunglasses, offering maximum protection and fashion-forward designs. From classic aviators to trendy oversized frames, find the perfect pair to elevate your look and keep your vision clear under the sun. Spectica sunglasses blend function with flair for every occasion.",
  };
  return (
    <div className="page-content">
    <Helmet>
        <title>Sunglassses </title>
        <meta name="description" content="lorem" />
       
    </Helmet>
    <CourseBanner {...bannerData}/>

    <section>
    <HomeProduct/>
    
    </section>



     
 



   

      


      
    </div>
  );
};

export default Sunglassses;
