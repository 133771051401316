import React ,{useEffect} from "react";
import { useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";
import CourseBanner from '../components/CourseBanner'
import HomeProduct from "../components/HomeProduct";

const Shop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  const bannerData = {
       
    image: "/images/service/shop-banner.jpg",
    heading: "SHOP",
    para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis facere reiciendis perspiciatis exercitationem sed consectetur maxime alias atque expedita dolorum.",
  };
  return (
    <div className="page-content">
    <Helmet>
        <title>Shop</title>
        <meta name="description" content="
        and innovation." />
        
    </Helmet>
    <CourseBanner {...bannerData}/>

    <section>
    <HomeProduct/>
    
    </section>



     
 



   

      


      
    </div>
  );
};

export default Shop;
