import React from "react";
import Slider from "react-slick";
import { Link, NavLink } from "react-router-dom";
import { FaRegFileLines } from "react-icons/fa6";

const HomeBanner = () => {
  const settings = {
    infinite: true,
    dots: true,
    arrows: false,
    slidesToShow: 1,
    speed: 2000,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
  };
  return (
    <div>
      <Slider {...settings}>
       
        <div className="swiper-slide">
          <div className="banner-item ">
            <div className="img">
              <img src="/images/home/banner-2.png" alt="" />
            </div>

          
          </div>
        </div>
        <div className="swiper-slide">
          <div className="banner-item ">
            <div className="img">
              <img src="/images/home/banner-3.png" alt="" />
            </div>
         
          </div>
        </div>
         <div className="swiper-slide">
          <div className="banner-item ">
            <div className="img">
              <img src="/images/home/banner-1.png" alt="" />
            </div>
         
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default HomeBanner;
