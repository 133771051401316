import React ,{useEffect} from "react";
import { useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";
import CourseBanner from '../../components/CourseBanner'
import HomeProduct from "../../components/HomeProduct";

const Kid = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  const bannerData = {
       
    image: "/images/service/kid.jpg",
    heading: "Kid's Glasses",
    para: "Spectica offers a fun and durable collection of kid's glasses, designed to withstand the energy of play while keeping young eyes protected. With vibrant colors and flexible frames, our eyewear makes clear vision exciting and comfortable for children. Choose from a variety of styles to fit every little face and personality.",
  };
  return (
    <div className="page-content">
    <Helmet>
        <title>Kid's Glasses</title>
        <meta name="description" content="
        and innovation." />
      
    </Helmet>
    <CourseBanner {...bannerData}/>

    <section>
    <HomeProduct/>
    
    </section>



     
 



   

      


      
    </div>
  );
};

export default Kid;
