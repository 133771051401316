import React from "react";
import { Col, Row } from "antd";
import { NavLink } from "react-router-dom";
import {
  FaLinkedin,
  FaSquareInstagram,
  FaYoutube,
  FaLocationDot,
  FaPhone,
  FaRegEnvelope,
  FaSquareWhatsapp,
} from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-4">
            <div className="footer-wrapper">
              <div className="wrapper">
                <div className="footer-logo">
                  <img src="/images/logo.png" alt="logo" />
                </div>
                <ul className="ps-0">
                  <li className="d-flex mt-4">
                    <span className="icon-box me-2">
                      <FaLocationDot />
                    </span>
                    <span>
                      <p className="">
                      J 12 A, behind Mcdonalds, J Block, Pocket J, Sector 18, Noida, Uttar Pradesh 201301
                      </p>
                    </span>
                  </li>

                  <li>
                    <span className="icon-box me-2">
                      <FaPhone />
                    </span>
                    <NavLink  className="effect effect-white"  to="tel:09899417056"> 09899417056</NavLink>
                  </li>
                  <li>
                    <span className="icon-box me-2">
                      <FaRegEnvelope />
                    </span>
                    <NavLink  className="effect effect-white" 
                      to="mailto:ureyecare@gmail.com"
                      target="_blank"
                      rel="noopener"
                    >
                    ureyecare@gmail.com
                    </NavLink>
                  </li>
                </ul>
                <div className="socal">
                  <p className="mb-0">
                    <strong>Follow Us</strong>
                  </p>
                
                  <NavLink  className="effect effect-white" 
                    to="https://www.facebook.com/specticaopticals"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaFacebookSquare />
                  </NavLink>
                  <NavLink  className="effect effect-white" 
                    to="https://www.instagram.com/specticaoptical/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaSquareInstagram />
                  </NavLink>
                 
                  
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="wrapper">
              <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3623378.1000350667!2d77.69137518447081!3d27.517314603755022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce44f4d1b2a95%3A0x9904f24dfdc69da!2sSpectica%20The%20Optical%20World!5e0!3m2!1sen!2sin!4v1704639976778!5m2!1sen!2sin"
                width="100%"
                height="350"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright">
        <div className="container">
          <p className="text-center mb-0">
            Copyright© 2022 ClearView - All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
