const ProductData = {
    data: [
        {   
            
            img1:"/images/products/1.1.jpg",
            name:"Crystal Wave",
            price1:"1000.00",
            price2:"699.00",
        },
        {   
            
            img1:"/images/products/2.1_grande.jpg",
            name:"Crystal Wave",
            price1:"1000.00",
            price2:"699.00",
        },
        {   
            
            img1:"/images/products/3.1.jpg",
            name:"Crystal Wave",
            price1:"1000.00",
            price2:"699.00",
        },
        {   
            
            img1:"/images/products/4.1_grande.jpg",
            name:"Crystal Wave",
            price1:"1000.00",
            price2:"699.00",
        },
        {   
            
            img1:"/images/products/4.2.jpg",
            name:"Crystal Wave",
            price1:"1000.00",
            price2:"699.00",
        },
        {   
            
            img1:"/images/products/Browline-Glasses4_grande.jpg",
            name:"Crystal Wave",
            price1:"1000.00",
            price2:"699.00",
        },
        {   
            
            img1:"/images/products/Geometric-Glasses1.1.jpg",
            name:"Crystal Wave",
            price1:"1000.00",
            price2:"699.00",
        },
        {   
            
            img1:"/images/products/Geometric-Glasses2.jpg",
            name:"Crystal Wave",
            price1:"1000.00",
            price2:"699.00",
        },
        {   
            
            img1:"/images/products/Mulholland-Sunglasses1.jpg",
            name:"Crystal Wave",
            price1:"1000.00",
            price2:"699.00",
        },
        {   
            
            img1:"/images/products/Premium-Rectangle1.jpg",
            name:"Crystal Wave",
            price1:"1000.00",
            price2:"699.00",
        },
        {   
            
            img1:"/images/products/Square-Glasses-S1.jpg",
            name:"Crystal Wave",
            price1:"1000.00",
            price2:"699.00",
        },
        {   
            
            img1:"/images/products/Square-Sunglasses1.jpg",
            name:"Crystal Wave",
            price1:"1000.00",
            price2:"699.00",
        },
       
       
      
    ],
  };

  export default ProductData