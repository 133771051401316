import React, { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { MdOutlineDoubleArrow } from "react-icons/md";

const Blog = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    const lowerCasePath = location.pathname.toLowerCase();
    if (location.pathname !== lowerCasePath) {
      navigate(lowerCasePath);
    }
  }, [location, navigate]);
  return (
    <div className="page-content blog-bg">
      <Helmet>
        <title>Blog</title>
        <meta
          name="description"
          content="Unlock the full potential of experiential learning with STEAM Varsity's 'Learning by Doing' blog. Discover how hands-on education fosters deeper understanding, enhances skills, and prepares you for real-world challenges. Embrace a learning approach that's as dynamic and innovative as you are."
        />
        <link rel="canonical" href="https://www.steamvarsity.com/blog/" />
      </Helmet>

      <div className="container all-blog-wrapper py-5">
        <div className="heading">
          <h1>Our Blogs</h1>
        </div>
        <div className="row mt-5">
          <h2>Latest blog</h2>
          <div className="col-md-12 mt-4">
            <NavLink to="/blog/eye-care-for-kids/">
              <div className="wrapper latest-blog">
                <div className="img-wrapper">
                  <img src="/images/blog/blog1.jpg" alt="img" />
                </div>

                <div className="text mt-3">
                  <span className="date">March 28, 2024 </span>
                  <h3 className="text-dark pt-3 mb-0">
                  Eye Care for Kids: Fun Frames and Healthy Habits
                  </h3>
                  <p>
                  Eye exams? Glasses? To a kid, it might not sound like the most exciting...
                  </p>
                  <span className="read-more">
                    Read More <MdOutlineDoubleArrow />
                  </span>
                </div>
              </div>
            </NavLink>
          </div>
          <h2 className="mt-5 mb-0">All Blogs</h2>
          <div className="col-md-6 mt-4">
            <NavLink to="/blog/eyeglasses-for-office/">
              <div className="wrapper ">
                <div className="img-wrapper">
                  <img src="/images/blog/blog4.jpg" alt="img" />
                </div>

                <div className="text mt-3">
                  <span className="date">March 20, 2024 </span>
                  <h3 className="text-dark pt-3 mb-0">
                  Eyeglasses for the Office: Balancing Style and Comfort
                  </h3>
                  <p>
                  Let's be honest, if you spend hours in front of a screen or scrutinizing...
                  </p>
                  <span className="read-more">
                    Read More <MdOutlineDoubleArrow />
                  </span>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="col-md-6 mt-4">
            <NavLink to="/blog/guide-to-choosing/">
              <div className="wrapper">
                <div className="img-wrapper">
                  <img src="/images/blog/blog2.jpg" alt="img" />
                </div>

                <div className="text mt-3">
                  <span className="date">March 10, 2024 </span>
                  <h3 className="text-dark pt-3 mb-0">The Ultimate Guide to Choosing the Right Eyeglasses for Your Face Shape: Finding Your Spec-tacular Match!</h3>
                 
                  <span className="read-more">
                    Read More <MdOutlineDoubleArrow />
                  </span>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="col-md-6 mt-4">
            <NavLink to="/blog/power-of-sunglasses/">
              <div className="wrapper">
                <div className="img-wrapper">
                  <img src="/images/blog/blog3.jpg" alt="img" />
                </div>

                <div className="text mt-3">
                  <span className="date">March 1, 2024 </span>
                  <h3 className="text-dark pt-3 mb-0">The Power of Sunglasses: Elevate Your Style and Protect Your Vision</h3>
                  <p>
                  Sunglasses – they're more than just a fashion statement....
                  </p>
                  <span className="read-more">
                    Read More <MdOutlineDoubleArrow />
                  </span>
                </div>
              </div>
            </NavLink>
          </div>
        
        </div>
      </div>
    </div>
  );
};

export default Blog;
