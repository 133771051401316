import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import CourseBanner from "../../components/CourseBanner";
import HomeProduct from "../../components/HomeProduct";

const ContactLenses = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const bannerData = {
    image: "/images/service/contact-lenses.jpg",
    heading: "Contact Lenses",
    para: "Experience clarity and comfort with Spectica's premium contact lenses, designed for all-day wear and optimal eye health. Our range caters to various prescriptions and lifestyles, ensuring you find the perfect fit for your vision needs. Trust Spectica to enhance your sight with lenses that offer both quality and comfort.",
  };
  return (
    <div className="page-content">
      <Helmet>
        <title>Contact Lenses</title>
        <meta name="description" content="lprem20" />
      </Helmet>
      <CourseBanner {...bannerData} />

      <section>
        <HomeProduct />
      </section>
    </div>
  );
};

export default ContactLenses;
