import React ,{useEffect} from "react";
import { useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";
import CourseBanner from '../../components/CourseBanner'
import HomeProduct from "../../components/HomeProduct";

const PremiumGlasses = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  const bannerData = {
       
    image: "/images/service/premium.jpg",
    heading: "Premium Glasses",
    para: "Explore the epitome of elegance with Spectica's premium glasses, where luxury meets lasting comfort and style. Crafted from high-quality materials, each pair is a testament to superior design and craftsmanship. Elevate your vision and style with our exquisite collection.",
  };
  return (
    <div className="page-content">
    <Helmet>
        <title>PremiumGlasses</title>
        <meta name="description" content="lorem" />
       
    </Helmet>
    <CourseBanner {...bannerData}/>

    <section>
    <HomeProduct/>
    
    </section>



     
 



   

      


      
    </div>
  );
};

export default PremiumGlasses;
