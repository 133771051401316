import React from "react";
import { NavLink  } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";


const HomeCategories = () => {
  const Brand = {
    data: [
        {  img1:"/images/brands-logo/brand1.png"},  
        {  img1:"/images/brands-logo/brand2.png"}, 
        {  img1:"/images/brands-logo/brand3.png"}, 
        {  img1:"/images/brands-logo/brand4.png"},  
        {  img1:"/images/brands-logo/brand5.png"}, 
        {  img1:"/images/brands-logo/brand6.png"},
        {  img1:"/images/brands-logo/brand7.png"},  
        {  img1:"/images/brands-logo/brand8.png"}, 
        {  img1:"/images/brands-logo/brand9.png"},  
        {  img1:"/images/brands-logo/brand10.png"},  
        {  img1:"/images/brands-logo/brand11.png"}, 
        {  img1:"/images/brands-logo/brand12.png"}, 
        {  img1:"/images/brands-logo/brand13.png"},  
        {  img1:"/images/brands-logo/brand14.png"}, 
        {  img1:"/images/brands-logo/brand15.png"},
        {  img1:"/images/brands-logo/brand16.png"},  
        {  img1:"/images/brands-logo/brand17.png"}, 
        {  img1:"/images/brands-logo/brand18.png"}, 
        
    ],
  };
  return (
    <section className="py-5">
      <div className="container categories-wrapper">
      <div className="heading text-center pb-4">
      <h2>Shop By Categories</h2>
      <p>Navigate through Spectica's diverse categories to find your perfect match.
      From chic to classic, discover eyewear that complements every style.</p>
      </div>
        <div className="row">
          <div className="col-md-4">
            <div className="wrapper">
            <div className="img-wrapper">
            <img src="/images/home/category-mens.jpg" alt="category" />
            </div>
            <div className="text-wrapper">
            <div className="text">
            <h3 className="text-white fw-5">
           Men’s <br /> Eyewear</h3>
            <NavLink  className="effect  effect-white"  to="/">Shop Now<FaAngleRight /></NavLink >
            
            </div>
            </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="wrapper">
            <div className="img-wrapper">
            <img src="/images/home/category-womens.jpg" alt="category" />
            </div>
            <div className="text-wrapper">
            <div className="text">
            <h3 className="text-white fw-5">
            Women’s <br /> Eyewear</h3>
            <NavLink  className="effect  effect-white"  to="/">Shop Now<FaAngleRight /></NavLink >
            
            </div>
            </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="wrapper">
            <div className="img-wrapper">
            <img src="/images/home/category-prescription.jpg" alt="category" />
            </div>
            <div className="text-wrapper">
            <div className="text">
            <h3 className="text-white fw-5">
            Prescription <br /> Glasses</h3>
            <NavLink  className="effect  effect-white"  to="/">Shop Now<FaAngleRight /></NavLink >
            
            </div>
            </div>
            </div>
          </div>
        </div>
      </div>

<div className="container brands-wrapper mt-5">
<div className="heading text-center pb-2">
<h2>Brands we are dealing</h2>

</div>
<div className="row">
{Brand.data.map((item, index)=>(
  <div className="col-lg-2 col-md-4 cl-sm-6" key={index}>
            <div className="wrapper">
            <div className="img-wrapper">
            <img src={item.img1} alt="brands" />
            </div>
            
            </div>
          </div>
))
}
          
         
        </div>

</div>

    </section>
  );
};

export default HomeCategories;
