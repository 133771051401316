import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import CourseBanner from "../components/CourseBanner";
import OurServices from "../components/OurServices";

const Service = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const bannerData = {
    image: "/images/service/service-banner.jpg",
    heading: "Our Services",
    para: "At Spectica, our commitment to comprehensive eye care is reflected in the breadth and depth of the services we offer. We understand the importance of accurate and thorough eye care, and our offerings are designed to meet all your vision needs with precision and personal attention.",
  };
  return (
    <div className="page-content">
      <Helmet>
        <title>Services</title>
        <meta
          name="description"
          content="
        and innovation."
        />
        <link rel="canonical" href="https://steamvarsity.com/about-us" />
      </Helmet>
      <CourseBanner {...bannerData} />
      <section className="bg2" id="service1" >
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6">
              <div className="wrapper">
                <h2>Zero Error 5 Points Computerised Eye Checkup</h2>
                <p>
                  Our state-of-the-art computerized eye checkup ensures a
                  zero-error diagnosis with a detailed 5-point examination. This
                  meticulous process assesses various aspects of your vision and
                  eye health, guaranteeing an accurate and comprehensive
                  understanding of your needs. Our expert optometrists use the
                  latest technology to provide you with a precise prescription
                  and a clear path to perfect vision.
                </p>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="img-wrapper">
                <img src="/images/service/service1.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="service2">
        <div className="container py-5">
          <div className="row">
 <div className="col-md-5">
              <div className="img-wrapper">
                <img src="/images/service/service2.jpg" alt="" />
              </div>
            </div>
<div className="col-md-1"></div>
            <div className="col-md-6">
              <div className="wrapper">
                <h2> Wide Range of Frames & Sunglasses</h2>

                <p>
                  We believe in providing our customers with an extensive
                  selection of eyewear options. Our collection includes a wide
                  range of frames and sunglasses from esteemed Indian and
                  international brands, ensuring that you find the perfect match
                  for your style and personality. Whether you're looking for the
                  latest fashion trends or classic designs that never go out of
                  style, our diverse assortment caters to all tastes and
                  preferences.
                </p>
              </div>
            </div>
            
           
          </div>
        </div>
      </section>
      <section className="bg2"   id="service3" >
        <div className="container py-5">
          <div className="row">
           
            
            <div className="col-md-6">
              <div className="wrapper">
                <h2>Comprehensive Contact Lens Selection</h2>

                <p>
                  At Spectica, we understand that contact lenses are a preferred
                  choice for many, offering convenience and flexibility. Our
                  extensive range of contact lenses caters to various
                  preferences and eye care needs, including daily disposables,
                  extended wear lenses, and specialized options for conditions
                  like astigmatism. Our knowledgeable staff is here to guide you
                  through the selection process, ensuring that you find the most
                  comfortable and suitable lenses for your lifestyle.
                </p>
              </div>
            </div>
<div className="col-md-1"></div>
             <div className="col-md-5">
              <div className="img-wrapper">
                <img src="/images/service/service3.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section  id="service4" >
        <div className="container py-5">
          <div className="row">
            <div className="col-md-5">
              <div className="img-wrapper">
                <img src="/images/service/service4.jpg" alt="" />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-6">
              <div className="wrapper">
                <h2>In-House Fitting Facility</h2>

                <p>
                  We take pride in our efficient service and customer
                  satisfaction. Our in-house fitting facility allows us to
                  provide same-day delivery of spectacles in many cases. Our
                  skilled technicians work meticulously to ensure that your
                  glasses are perfectly fitted to your specifications, providing
                  comfort and optimal vision. We understand that your time is
                  valuable, and we strive to make the process as convenient and
                  pleasant as possible.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg2"  id="service5" >
        <div className="container py-5">
          <div className="row">
            
            
            <div className="col-md-6">
              <div className="wrapper">
                <h2> Quality Ophthalmic Lenses</h2>

                <p>
                  In our quest to provide the best in eye care, we offer a wide
                  range of ophthalmic lenses from recognized international and
                  national brands such as Zeiss, Essilor, Hoya, Nikon, Shamir,
                  and Rodenstock. These brands are synonymous with quality and
                  innovation, offering lenses that are not just about vision
                  correction but also comfort, protection, and style. Whether
                  you need single vision lenses, progressives, or specialized
                  coatings, we have the perfect solution for your unique needs.
                </p>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className="img-wrapper">
                <img src="/images/service/service5.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Service;
