import React ,{useEffect} from "react";
import { useLocation } from "react-router-dom";
import {Helmet} from "react-helmet";
import CourseBanner from '../../components/CourseBanner'
import HomeProduct from "../../components/HomeProduct";

const WomenGlasses = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  const bannerData = {
       
    image: "/images/service/women.jpg",
    heading: "Women's Glasses",
    para: "Discover Spectica's women's glasses collection, where fashion-forward designs meet comfort and clarity. Choose from an array of styles, from elegant and sophisticated to bold and modern, all crafted to enhance your vision and complement your unique style. Embrace your individuality with eyewear that's as distinctive as you are.",
  };
  return (
    <div className="page-content">
    <Helmet>
        <title>Women Glasses</title>
        <meta name="description" content="Women Glasses" />
      
    </Helmet>
    <CourseBanner {...bannerData}/>

    <section>
    <HomeProduct/>
    
    </section>



     
 



   

      


      
    </div>
  );
};

export default WomenGlasses;
