import React, { useEffect } from "react";
import { Col, Row, Form } from "antd";
import Forme from "../components/FormComponent";
import "./home.css";
import HomeBanner from "../components/HomeBanner";
import { NavLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import HomeCategories from "../components/HomeCategories";
import HomeProduct from "../components/HomeProduct";
import { FaAngleRight } from "react-icons/fa";
import OurServices from "../components/OurServices";
const Home = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="page-content">
      <Helmet>
        <title>Spectica</title>
        <meta name="description" content="lorem" />
      </Helmet>

      <div className="home-banner-wrapper">
        <HomeBanner />
      </div>
      <HomeCategories />
      <HomeProduct />
      <OurServices />
      <section className="offer-wrapper position-relative">
        <div className="img-wrapper ">
          <img src="/images/home/bg-offers.jpg" alt="" />
        </div>
        <div className="text-container">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="wrapper">
                  <div className="text-wrapper">
                    <h2>Protect Your Vision</h2>
                    <p>
                    Learn about the crucial role of UV protection in eyeglasses. Explore Spectica's range of UV-protective eyewear to safeguard your eyes in style.
                    </p>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-5 service-wrapper">
          <div className="heading text-center pb-4">
            <h2>Our Blogs</h2>
            <p>
            Elevate Your Style with Spectica's Range of Fashionable Eyeglasses
            </p>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="wrapper">
                <div className="img-wrapper">
                  <img src="/images/blog/blog1.jpg" alt="" />
                </div>
                <div className="text-wrapper">
                  <div className="text">
                    <p>March 28, 2024</p>
                    <h3>Eye Care for Kids: Fun Frames and Healthy Habits</h3>
                    <p>
                    Eye exams? Glasses? To a kid, it might not sound like the most exciting...
                    </p>

                    <NavLink className="effect" to="/blog/eye-care-for-kids/">
                      Read More
                      <FaAngleRight />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="wrapper">
                <div className="img-wrapper">
                  <img src="/images/blog/blog2.jpg" alt="" />
                </div>
                <div className="text-wrapper">
                  <div className="text">
                    <p>March 20, 2024</p>
                    <h3>Eyeglasses for the Office: Balancing Style and Comfort
                  </h3>
                    <p>
                    Let's be honest, if you spend hours in front of a screen or scrutinizing...
                    </p>

                    <NavLink className="effect" to="/blog/eyeglasses-for-office/">
                      Read More
                      <FaAngleRight />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="wrapper">
                <div className="img-wrapper">
                  <img src="/images/blog/blog3.jpg" alt="" />
                </div>
                <div className="text-wrapper">
                  <div className="text">
                    <p>March 10, 2024</p>
                    <h3>The Ultimate Guide to Choosing the Right Eyeglasses for Your Face Shape: Finding Your Spec-tacular Match!</h3>
                    

                    <NavLink className="effect" to="/blog/guide-to-choosing/">
                      Read More
                      <FaAngleRight />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
