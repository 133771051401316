import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const EyeCareKids = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    const lowerCasePath = location.pathname.toLowerCase();
    if (location.pathname !== lowerCasePath) {
      navigate(lowerCasePath);
    }
  }, [location, navigate]);
  return (
    <div className="page-content blog-bg">
      <Helmet>
        <title>Problem-Based Learning at STEAM Varsity</title>
        <meta
          name="description"
          content="- Problem-based learning is the most effective pedagogical approach that empowers students to master scientific concepts while experiencing real-life scenarios."
        />

        <link
          rel="canonical"
          href="https://www.steamvarsity.com/blog/problem-based-learning/"
        />
      </Helmet>

      <div className="container">
        <div className="  blog-detail-wrapper py-5">
          <div className="detail-wrapper pb-5 pt-1  border-bottom ">
            <div className="content-banner">
              <div className="img">
                <img src="/images/blog/blog1.jpg" alt="blog" />
              </div>
              <p>March 28, 2024</p>
            </div>
          </div>

          <div className="contents pt-4">
            <h1 className="Bold">
              Eye Care for Kids: Fun Frames and Healthy Habits
            </h1>
            <p>
              Eye exams? Glasses? To a kid, it might not sound like the most
              exciting stuff. But as a parent, you know that good vision is
              crucial for your child's development, learning, and overall
              well-being. Here's how to make eye care fun and build healthy
              habits that last a lifetime.
            </p>
            <h3>The Importance of Eye Exams</h3>
            <p>
              Kids don't always complain if their vision is blurry. That's why
              regular eye exams are a must! Think of an eye doctor as a
              superhero detective who uncovers any hidden vision problems.
              Here's what they can catch:
            </p>
            <ul className="blog-list-wrapper">
              <li>
                <strong>Nearsightedness, Farsightedness, Astigmatism:</strong>
                The blurry vision culprits that glasses can fix!
              </li>
              <li>
                <strong>Lazy Eye and Other Conditions:</strong>Early detection
                and treatment is key with these.
              </li>
            </ul>
          </div>
          <div className="contents">
            <h2>Choosing Glasses: Let the Fun Begin!</h2>
            <p>
              Getting glasses should feel like an adventure. Here's how to make
              it a positive experience:
            </p>
            <ul className="blog-list-wrapper">
              <li>
                <strong>A Rainbow of Colors:</strong> Let your child express
                their personality with bright colors, fun shapes, or even their
                favorite character frames.
              </li>
              <li>
                <strong>Comfort is King:</strong> Frames should fit well and not
                feel heavy. A good optician will help them find the perfect fit.
              </li>
              <li>
                <strong>Durability Matters:</strong>
                Kids are active! Look for sturdy materials that can stand up to
                playtime.
              </li>
            </ul>
          </div>

          <div className="contents">
            <h2>Healthy Eye Habits to Teach</h2>
            <p>
              Eye care goes beyond glasses! Help your child build these habits:
            </p>
            <ul className="blog-list-wrapper">
              <li>
                <strong>The 20-20-20 Rule:</strong> Break up screen time! Every
                20 minutes, look at something 20 feet away for 20 seconds.
              </li>
              <li>
                <strong>Sun Protection:</strong> Sunglasses are cool, and good
                for their eyes too!
              </li>
              <li>
                <strong>Hands Off:</strong>
                Rubbing eyes can spread germs. Teach them to wash hands instead.
              </li>
              <li>
                <strong>Nutrition Boost:</strong> Snack on colorful fruits and
                veggies – great for eye health!
              </li>
            </ul>
          </div>
          <div className="contents">
            <h2>Make it a Family Affair</h2>
            <p>
            When kids see you wearing your glasses and practicing healthy eye habits, they're more likely to model it too. Talk about the importance of eye care and make those yearly check-ups a normal part of your family routine.
            </p>

           <h3>Remember, clear vision is a gift! With a little fun and some easy habits, you can help your child protect their eyesight for a lifetime of clear adventures.</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EyeCareKids;
